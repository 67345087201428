import React from 'react';
import { navigate } from 'gatsby';

import SearchForm from '../../../../components/Search/SearchItems/SearchForm';
import buildSearchUrl from '../../../../utils/search/buildSearchUrl';
import useSearchContext from '../../../../utils/context/SearchContext';

// Types
import { ChangeEvent, FormEvent } from 'react';

type Props = {};

const SearchFormContainer = (_props: Props) => {
  const { term } = useSearchContext();

  const [nextTerm, setNextTerm] = React.useState<string>('');

  React.useEffect(() => {
    if (nextTerm.length === 0 && !!term && term.length > 0) {
      setNextTerm(term);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  const handleNextTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNextTerm(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    navigate(buildSearchUrl(nextTerm));
  };

  return <SearchForm onChange={handleNextTermChange} onSubmit={handleSubmit} term={nextTerm} />;
};

export default SearchFormContainer;
