/* eslint-disable no-underscore-dangle */

import React from 'react';
import lunr from 'lunr';
import { globalHistory } from '@reach/router';
import { withPrefix } from 'gatsby';

import envConfig from '../../config/env';
import notNullOrUndefined from '../misc/notNullOrUndefined';
import routes from '../../config/routes';
import useSearchContext from '../context/SearchContext';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../types/global';
import { FluidImage } from '../../components/MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { WindowLocation } from '@reach/router';

type RugSearchResultRaw = {
  award: CMS_ENUM_RUG_AWARD | null;
  collectionName: string | null;
  collectionSlug: string | null;
  color: string | null;
  created_at: string;
  extra: string | null;
  id: string;
  material: string | null;
  name: string;
  picture: string | null;
};

type RugSearchResultPicture = {
  __typename: 'CMS_UploadFile';
  file: { __typename: 'File'; childImageSharp: { __typename: 'ImageSharp'; fluid: FluidImage | null } };
  name: string;
  url: string;
};

type RugSearchResult = {
  __typename: 'CMS_Rug';
  award: CMS_ENUM_RUG_AWARD | null;
  collectionName: string | null;
  collectionSlug: string | null;
  color: string | null;
  created_at: string;
  extra: string | null;
  id: string;
  isVisibleOnWebsite: boolean;
  material: string | null;
  name: string;
  picture: RugSearchResultPicture | null;
};

const getSearchTermFromLocation = (location: WindowLocation) => {
  const searchTerm = new URLSearchParams(location.search).get('term') || '';

  if (typeof searchTerm !== 'string' || searchTerm.length < 1) {
    return null;
  }

  return decodeURIComponent(searchTerm);
};

const parsePictureString = (picture: string | null): FluidImage | null => {
  if (!picture) {
    return null;
  }

  try {
    const parsedPicture = JSON.parse(picture) as FluidImage;

    return parsedPicture;
  } catch (err) {
    if (envConfig.isDev) {
      // eslint-disable-next-line no-console
      console.log(err);
    }

    return null;
  }
};

const normalizeRug = (rug: RugSearchResultRaw) => {
  const fluidPicture = parsePictureString(rug.picture);

  const fakedFluidPicturePath: RugSearchResultPicture = {
    __typename: 'CMS_UploadFile',
    file: {
      __typename: 'File',
      childImageSharp: {
        __typename: 'ImageSharp',
        fluid: fluidPicture,
      },
    },
    name: 'searchResult',
    url: 'searchResult',
  };

  const normalizedRug: RugSearchResult = {
    ...rug,
    __typename: 'CMS_RUG' as 'CMS_Rug',
    isVisibleOnWebsite: true,
    picture: fakedFluidPicturePath,
  };

  return normalizedRug;
};

const getLunrInstance = async () => {
  if (typeof window !== 'undefined') {
    if (notNullOrUndefined((window as any).__LUNR__)) {
      return (window as any).__LUNR__;
    }

    try {
      const fullIndex = await fetch(withPrefix(`/${envConfig.searchIndexFilename}`)).then(response => response.json());

      const lunrInstance = Object.keys(fullIndex).reduce(
        (prev, key) => ({
          ...prev,
          [key]: {
            index: lunr.Index.load(fullIndex[key].index),
            store: fullIndex[key].store,
          },
        }),
        {},
      );

      (window as any).__LUNR__ = lunrInstance;

      return lunrInstance;
    } catch (err) {
      if (envConfig.isDev) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      return null;
    }
  }

  return null;
};

// `Lunr` has support for more complex queries, but with this user's are able to break stuff.
// So we clean the term of all these `Lunr` commands.
// See https://lunrjs.com/guides/searching.html for more information.
const cleanTerm = (term: string) => {
  const cleanedTerm = term.replace(/\*|\+|-|:|\^|~/gi, '');

  return cleanedTerm;
};

// By default, `Lunr` combines each term with a `OR`, but we want them to be combined by `AND`s.
// So we add a `+` to each term to make it so.
// See https://lunrjs.com/guides/searching.html for more information.
const forceTermPresence = (term: string) => {
  const forcedPresenceTerm = `+${term.split(' ').join(' +')}`;

  return forcedPresenceTerm;
};

const useSearch = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { results, setSearchResults, setSearchTerm, term } = useSearchContext();

  const location = globalHistory.location;

  React.useEffect(() => {
    if (location.pathname.includes(routes.search)) {
      const nextSearchTerm = getSearchTermFromLocation(location);

      setSearchTerm(nextSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    const updateResults = async () => {
      if (!!term) {
        setIsLoading(true);

        const lunrInstance = await getLunrInstance();

        const cleanedTerm = cleanTerm(term).trim();

        // Using an empty term might break the search, since this will not return useful results anyway,
        // we return an empty results array right away.
        if (cleanedTerm.length === 0) {
          setSearchResults([]);

          setIsLoading(false);

          return;
        }

        const forcedPresenceTerm = forceTermPresence(cleanedTerm).trim();

        const refs = lunrInstance.en.index.search(forcedPresenceTerm);

        const searchResults = (refs.map(({ ref }: { ref: string }) => lunrInstance.en.store[ref]) || []) as RugSearchResultRaw[];

        const normalizedSearchResults = searchResults.map(normalizeRug);

        setSearchResults(normalizedSearchResults);

        setIsLoading(false);
      }
    };

    // We only want to fire it when we're actually on the search page.
    if (typeof window !== 'undefined' && location.pathname.includes(routes.search)) {
      updateResults();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return { isLoading, results, term };
};

export default useSearch;
