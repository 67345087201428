import React from 'react';

import LayoutContainer from '../containers/Layout';
import SearchPageContainer from '../containers/Search/SearchPage';
import theme from '../utils/styling/theme';

const SearchPagePage = () => {
  return (
    <LayoutContainer logoColor={theme.color.brand}>
      <SearchPageContainer />
    </LayoutContainer>
  );
};

export default SearchPagePage;
