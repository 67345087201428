import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

export const Form = styled.form``;

export const Button = styled.button`
  ${styleUtils.margin.vertical.topQuarter};

  border-left: 0;
  padding: 0.5rem 1rem;

  background-color: ${props => props.theme.color.brand};
  border: 1px solid ${props => props.theme.color.brand};

  ${styleUtils.animation.searchButtonHover};
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.white};
`;

export const Input = styled.input`
  ${styleUtils.margin.vertical.topQuarter};

  border: 1px solid ${props => props.theme.color.black40};
  border-right: 0;
  outline-color: ${props => props.theme.color.brand};
  padding: 0.5rem 0.75rem;
  width: 80%;
`;
