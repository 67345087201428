import React from 'react';

import SectionOverviewIntro from '../../Section/SectionOverviewIntro';
import SearchSectionRugs from '../SearchSections/SearchSectionRugs';
import useSearchContext from '../../../utils/context/SearchContext';

// Types
import { ReactNode } from 'react';

type Props = {
  appInfo: ReactNode;
  appInfoPortrait: ReactNode;
  carousel: ReactNode;
  footer: ReactNode;
  isLoading: boolean;
  resultsCount: number;
  rugs: ReactNode;
  searchForm: ReactNode;
  titleHeaderPortrait: ReactNode;
};

const SearchPage = ({
  appInfo,
  appInfoPortrait,
  carousel,
  footer,
  isLoading,
  resultsCount,
  rugs,
  searchForm,
  titleHeaderPortrait,
}: Props) => {
  const { term } = useSearchContext();

  return (
    <>
      <SectionOverviewIntro appInfo={appInfo} titleHeaderPortrait={titleHeaderPortrait}>
        {searchForm}
      </SectionOverviewIntro>

      <SearchSectionRugs isLoading={isLoading} resultsCount={resultsCount} rugs={rugs} term={term} />

      {appInfoPortrait}

      {carousel}

      {footer}
    </>
  );
};

export default SearchPage;
