import React from 'react';
import idx from 'idx';
import { graphql, useStaticQuery } from 'gatsby';

import SectionFooterContact from '../../../components/Section/SectionFooterContact';
import SectionFooterContactContact from '../../../components/Section/SectionFooterContact/SectionFooterContactContact';
import SectionItemFooterSocialContainer from '../SectionItems/SectionItemFooterSocial';

// Types
import { SectionFooterContactDefaultContainerQuery } from './__generated__/SectionFooterContactDefaultContainerQuery';

type Props = {
  backgroundColor: string;
  textColor: string;
};

const SectionFooterContactDefaultContainer = ({ backgroundColor, textColor }: Props) => {
  const data = useStaticQuery<SectionFooterContactDefaultContainerQuery>(graphql`
    query SectionFooterContactDefaultContainerQuery {
      site {
        siteMetadata {
          contact {
            hq {
              cell
              city
              country
              email
              fax
              name
              phone
              postalCode
              street
              streetNumber
            }
            press {
              cell
              city
              country
              email
              fax
              name
              phone
              postalCode
              street
              streetNumber
            }
          }
        }
      }
    }
  `);

  const hqContactData = idx(data, _ => _.site.siteMetadata.contact.hq) || null;
  const pressContactData = idx(data, _ => _.site.siteMetadata.contact.press) || null;

  if (!hqContactData && !pressContactData) {
    return null;
  }

  const social = <SectionItemFooterSocialContainer textColor={textColor} />;

  const hqContact = !!hqContactData ? (
    <SectionFooterContactContact
      cell={hqContactData.cell}
      city={hqContactData.city}
      country={hqContactData.country}
      email={hqContactData.email}
      fax={hqContactData.fax}
      headline={hqContactData.name}
      name={null}
      phone={hqContactData.phone}
      postalCode={hqContactData.postalCode}
      street={hqContactData.street}
      streetNumber={hqContactData.streetNumber}
      textColor={textColor}
    />
  ) : null;

  const pressContact = !!pressContactData ? (
    <SectionFooterContactContact
      cell={pressContactData.cell}
      city={pressContactData.city}
      country={pressContactData.country}
      email={pressContactData.email}
      fax={pressContactData.fax}
      headline="Press Contact"
      name={pressContactData.name}
      phone={pressContactData.phone}
      postalCode={pressContactData.postalCode}
      street={pressContactData.street}
      streetNumber={pressContactData.streetNumber}
      textColor={textColor}
    />
  ) : null;

  return (
    <SectionFooterContact
      backgroundColor={backgroundColor}
      backgroundImage={null}
      backgroundPattern={null}
      contact={
        <>
          {hqContact}
          {pressContact}
        </>
      }
      isHiddenOnMobile={false}
      social={social}
      textColor={textColor}
    />
  );
};

export default SectionFooterContactDefaultContainer;
