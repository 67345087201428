import React from 'react';

import envConfig from '../../config/env';

const useGetPreviousUrl = () => {
  const [previousUrl, setPreviusUrl] = React.useState<string | null>(null);

  const previousPath = typeof window !== 'undefined' ? (window as any).previousPath : null;

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (typeof previousPath === 'string' && previousPath.length > 0) {
        setPreviusUrl(previousPath.replace(envConfig.appUrl, ''));
      } else {
        setPreviusUrl(null);
      }
    }
  }, [previousPath]);

  return previousUrl;
};

export default useGetPreviousUrl;
