import React from 'react';
import pluralize from 'pluralize';

import Heading from '../../../components/Heading';
import SEOContainer from '../../Layout/SEO';
import SearchFormContainer from '../SearchItems/SearchForm';
import SearchPage from '../../../components/Search/SearchPage';
import SearchPageBreadcrumb from '../../../utils/seo/SearchPageBreadcrumb';
import SearchSectionRugsRugContainer from '../SearchSections/SearchSectionRugs/SearchSectionRugsRug';
import Section from '../../../components/Heading/Section';
import SectionCarouselCollectionsContainer from '../../Section/SectionCarouselCollections';
import SectionFooterContactDefaultContainer from '../../Section/SectionFooterContactDefault';
import SectionOverviewIntroAppInfoContainer from '../../Section/SectionOverviewIntro/SectionOverviewIntroAppInfo';
import TitleHeaderPortraitContainer from '../../TitleHeader/TitleHeaderPortrait';
import buildContentPageUrl from '../../../utils/url/buildContentPageUrl';
import routes from '../../../config/routes';
import theme from '../../../utils/styling/theme';
import useGetPreviousUrl from '../../../utils/hooks/useGetPreviousUrl';
import useHeaderContext from '../../../utils/context/HeaderContext';
import useSearch from '../../../utils/hooks/useSearch';
import { sortNewRugsToFront } from '../../Collection/CollectionOverview/utils';

type Props = {};

const SearchPageContainer = (_props: Props) => {
  const canonicalUrl = buildContentPageUrl(routes.search);

  const previousUrl = useGetPreviousUrl();
  const { isLoading, results, term } = useSearch();
  const { setHeader } = useHeaderContext();

  const appInfo = <SectionOverviewIntroAppInfoContainer />;
  const appInfoPortrait = <SectionOverviewIntroAppInfoContainer isPortraitVariant={true} />;
  const carousel = <SectionCarouselCollectionsContainer isHiddenOnMobile={false} />;
  const footer = <SectionFooterContactDefaultContainer backgroundColor={theme.color.white} textColor={theme.color.black} />;
  const searchForm = <SearchFormContainer />;
  const titleHeaderPortrait = <TitleHeaderPortraitContainer />;

  const mappedRugs = sortNewRugsToFront(results).map((rug, index) => (
    <SearchSectionRugsRugContainer isCritical={index <= 4} key={rug.id} rug={rug} slug={rug.collectionSlug} />
  ));

  const resultsCount = React.Children.count(mappedRugs);

  React.useEffect(() => {
    const subtitle = !!term ? `${resultsCount} ${pluralize('Result', resultsCount)}` : ' ';

    setHeader({ backButtonUrl: previousUrl, title: 'Search', subtitle });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsCount, previousUrl, term]);

  return (
    <>
      {/* SEO */}
      <SearchPageBreadcrumb />
      <SEOContainer canonicalUrl={canonicalUrl} title="Search" openGraphTitle="Search" />

      <Heading isVisuallyHidden={true}>Search</Heading>

      <Section hasNoTag={true}>
        <SearchPage
          appInfo={appInfo}
          appInfoPortrait={appInfoPortrait}
          carousel={carousel}
          footer={footer}
          isLoading={isLoading}
          resultsCount={resultsCount}
          rugs={mappedRugs}
          searchForm={searchForm}
          titleHeaderPortrait={titleHeaderPortrait}
        />
      </Section>
    </>
  );
};

export default SearchPageContainer;
