import React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import * as S from './styles';

// Types
import { ChangeEvent, FormEvent } from 'react';

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  term: string;
};

const SearchForm = ({ term, onChange, onSubmit }: Props) => {
  return (
    <S.Form onSubmit={onSubmit} role="search">
      <S.Input onChange={onChange} placeholder="Rug Name" type="text" value={term} />

      <S.Button type="submit">
        <S.ButtonIcon icon={faSearch} size="sm" />
      </S.Button>
    </S.Form>
  );
};

export default SearchForm;
